import {Popover, Tooltip} from 'bootstrap';
import NavPillsResponsive from '../components/NavPillsResponsive';
import StarRating from '../components/StarRating';


export class ComponentsUtils {

    /**
     * Initializes the all bootstrap popovers on the page.
     * @param selector of the popover objects
     * @returns array of bootstrap Popover objects
     */
    static setupPopovers(selector: string): Popover[] {
        const popoverOwnerElements = [].slice.call(document.querySelectorAll(selector))
        return popoverOwnerElements.map(function (popoverElem: HTMLElement) {
            const contentSelector = popoverElem.getAttribute('data-content-selector')
            const popoverContentElems = contentSelector ? document.querySelectorAll(contentSelector) : null;

            if (popoverContentElems && popoverContentElems.length > 0) {
                const content = popoverContentElems.item(0);
                content.remove(); // remove the content from dom
                return new Popover(popoverElem, {
                    html: true,
                    content: content,
                    template: '<div class="popover popover-with-html" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
                })
            } else {
                return new Popover(popoverElem)
            }
        })
    }

    /**
     * Initializes the bootstrap tooltips on the page
     * @param selector
     * @returns
     */
    static setupTooltips(selector = '[data-bs-toggle="tooltip"]'): Tooltip {
        const tooltipTriggerList = [].slice.call(document.querySelectorAll(selector))
        return tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
        })
    }

    /**
     * Enables the default bootstrap forms validation.
     */
    static enableDefaultBootstrapFormsValidation(selector='.needs-validation') {
        const forms = document.querySelectorAll(selector);

        Array.prototype.slice.call(forms)
            .forEach(function(form: HTMLFormElement) {
                form.addEventListener('submit', function(event: Event) {
                    if (!form.checkValidity()) {
                        event.preventDefault()
                        event.stopPropagation()
                    }

                    form.classList.add('was-validated')
                }, false)
            })
    }

    static initResponsiveNavPills(selector: string) {
        const navPillsElements = [].slice.call(document.querySelectorAll(selector))
        return navPillsElements.map((navPillsElem: HTMLElement) => {
            return new NavPillsResponsive(navPillsElem);
        })
    }

    static initStarRating() {
        const popoverOwnerElements = [].slice.call(document.querySelectorAll('input.star-rating'))
        return popoverOwnerElements.map((inputElem: HTMLElement) => {
            return new StarRating(<HTMLInputElement>inputElem);
        })
    }

    static addScrolledClassToNavbarOnScroll() {
        const navbar = document.querySelector('.top-navbar')
        if (!navbar) return;

        const updateScrolledState = () => {
            navbar.classList.toggle('scrolled', window.scrollY != 0);
        }

        window.addEventListener('scroll', (e) => {
            updateScrolledState();
        })

        updateScrolledState();
    }

    static addClassToBodyWhenMobileNavbarMenuOpens(className="menu-opened") {
        const body = document.querySelector('body');
        const navbarCollapse = document.querySelector('.navbar-collapse');
        if (!body || !navbarCollapse) return;

        navbarCollapse.addEventListener('show.bs.collapse', (e) => {
            body.classList.add(className)
        })

        navbarCollapse.addEventListener('hidden.bs.collapse', (e) => {
            body.classList.remove(className)
        })
    }
}


export function getClosest( elem: HTMLElement, selector: string ) {

    // Variables
    let firstChar = selector.charAt(0);
    let supports = 'classList' in document.documentElement;
    let attribute, value;

    // If selector is a data attribute, split attribute from value
    if ( firstChar === '[' ) {
        selector = selector.substr( 1, selector.length - 2 );
        attribute = selector.split( '=' );

        if ( attribute.length > 1 ) {
            value = true;
            attribute[1] = attribute[1].replace( /"/g, '' ).replace( /'/g, '' );
        }
    }

    // Get closest match
    for ( ; elem && elem !== document.body && elem.nodeType === 1; elem = <HTMLElement>elem.parentNode ) {

        // If selector is a class
        if ( firstChar === '.' ) {
            if ( supports ) {
                if ( elem.classList.contains( selector.substr(1) ) ) {
                    return elem;
                }
            } else {
                if ( new RegExp('(^|\\s)' + selector.substr(1) + '(\\s|$)').test( elem.className ) ) {
                    return elem;
                }
            }
        }

        // If selector is an ID
        if ( firstChar === '#' ) {
            if ( elem.id === selector.substr(1) ) {
                return elem;
            }
        }

        // If selector is a data attribute
        if ( firstChar === '[' ) {
            if ( elem.hasAttribute( attribute[0] ) ) {
                if ( value ) {
                    if ( elem.getAttribute( attribute[0] ) === attribute[1] ) {
                        return elem;
                    }
                } else {
                    return elem;
                }
            }
        }

        // If selector is a tag
        if ( elem.tagName.toLowerCase() === selector ) {
            return elem;
        }

    }

    return null;

}
