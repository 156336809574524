declare global {
    interface Window {
        bootstrap:any;
        jQuery:JQueryStatic;
        $:any;
        initApp:({language}) => void
    }
}

window.bootstrap = require('bootstrap5');

import {ComponentsUtils} from './app/utils/ComponentsUtils'
import {tns} from "tiny-slider"

window.initApp = () => {
    ComponentsUtils.setupPopovers('[data-bs-toggle="popover"]');
    ComponentsUtils.setupTooltips();
    ComponentsUtils.addClassToBodyWhenMobileNavbarMenuOpens();
    ComponentsUtils.addScrolledClassToNavbarOnScroll();
    initCompetitionsSlider();
    initChallengesSlider();
    initEventsSlider();
    initReviewsSlider();
    initHeroCompatibleToolsSlider();
    console.log('Application initialized');
}

function initCompetitionsSlider() {
    const slidesContainer = document.querySelector('.competitions-slider');
    if (!slidesContainer) return;

    tns({
        container: slidesContainer,
        items: 1.4,
        autoplay: false,
        controls: false,
        mouseDrag: true,
        nav: false,
        autoHeight: false,
        // gutter: 15, // padding between slides

        responsive: {
            0: {
                items: 1,
                edgePadding: 30,
            },
            576: {
                items: 1.4,
                edgePadding: 0,
            },
            768: {
                items: 2.4
            },
            992: {
                items: 3.4
            },
            1400: {
                items: 4.4
            }
        }
    });

    console.log('competitions slider initialized')
}

function initEventsSlider() {
    const slidesContainer = document.querySelector('.events-slider');
    if (!slidesContainer) return;

    tns({
        container: slidesContainer,
        items: 1.4,
        autoplay: false,
        controls: false,
        mouseDrag: true,
        nav: false,
        autoHeight: false,
        gutter: 15, // padding between slides

        responsive: {
            0: {
                items: 1,
                edgePadding: 30,
            },
            576: {
                items: 1.4,
                edgePadding: 0,
            },
            768: {
                items: 1.4,
                edgePadding: 0,
            },
            992: {
                items: 3.4
            },
            1400: {
                items: 4.4
            }
        }
    });

    console.log('events slider initialized')
}

function initChallengesSlider() {
    const slidesContainer = document.querySelector('.challenges-slider');
    if (!slidesContainer) return;

    tns({
        container: slidesContainer,
        items: 1.4,
        autoplay: false,
        controls: false,
        mouseDrag: true,
        nav: false,
        autoHeight: false,
        gutter: 15, // padding between slides

        responsive: {
            0: {
                items: 1,
                edgePadding: 30,
            },
            576: {
                items: 1.4,
                edgePadding: 0,
            },
            768: {
                items: 1.4,
                edgePadding: 0,
            },
            992: {
                items: 3.4
            },
            1400: {
                items: 4.4
            }
        }
    });

    console.log('challenges slider initialized')
}

function initReviewsSlider() {
    const slidesContainer = document.querySelector('.reviews-slider');
    if (!slidesContainer) return;

    const slider = tns({
        container: slidesContainer,
        items: 1,
        autoplay: false,
        controls: false,
        mouseDrag: true,
        nav: false,
        autoHeight: false,
        gutter: 15,
    });

    slidesContainer.addEventListener('click', (e) => {
        const targetElement = <HTMLElement>e.target;
        if (targetElement.closest('.btn-next')) {
            slider.goTo('next')
        } else if (targetElement.closest('.btn-prev')) {
            slider.goTo('prev')
        }
    });

    console.log('reviews slider initialized')
}

function initHeroCompatibleToolsSlider() {
    const slidesContainer = document.querySelector('.compatible-tools-slider');
    if (!slidesContainer) return;

    tns({
        container: slidesContainer,
        items: 3,
        autoplay: true,
        controls: false,
        mouseDrag: true,
        nav: false,
        autoHeight: false,
        gutter: 15, // padding between slides
        edgePadding: 30,
        autoplayButtonOutput: false,

        responsive: {
            576: {
                items: 5,
            }
        }
    });
}


function setup_subscription_card() {
    var subscribe_level = <HTMLSelectElement>document.getElementById("subscribe_level");
    var subscribe_amount = <HTMLInputElement>document.getElementById("subscribe_amount");
    var subscription_result = <HTMLDivElement>document.getElementById("subscription_result");

    if (subscribe_level.value == "" || parseFloat(subscribe_amount.value) == 0) {
        subscription_result.classList.add("d-none");
        return;
    }

    var subscribe_amount2 = parseFloat(subscribe_amount.value);
    if (subscribe_amount2 < 50) {
        subscribe_amount2 = 50;
    }
    var total_price = parseFloat(subscribe_level.value) * subscribe_amount2;
    document.getElementById("subscription_result_amount").innerHTML = String(total_price);
    document.getElementById("subscription_result_participants").innerHTML = String(subscribe_amount2);

    if (subscribe_level.value == "1") {
        document.getElementById("subscription_result_kind").innerHTML = document.getElementById("subscription_result_kind").getAttribute('data-one');
    } else {
        document.getElementById("subscription_result_kind").innerHTML = document.getElementById("subscription_result_kind").getAttribute('data-three');
    }

    subscription_result.classList.remove("d-none");
}

if (document.getElementById("subscribe_level")) {

    var subscribe_level = <HTMLSelectElement>document.getElementById("subscribe_level");
    var subscribe_amount = <HTMLInputElement>document.getElementById("subscribe_amount");
    setup_subscription_card();

    subscribe_level.onchange = (ev: Event) => {
        setup_subscription_card();
    }

    subscribe_amount.onchange = (ev: Event) => {
        setup_subscription_card();
    }
}


if (document.querySelector('.event_filter_btn')) {
    const filter_btns: NodeListOf<any> = document.querySelectorAll('.event_filter_btn')
    for (let i = 0; i < filter_btns.length; i++) {
        const btn = filter_btns[i];
        btn.onclick = (ev: MouseEvent) => {
            const filter_value = btn.dataset.filter;
            const input = <HTMLInputElement>document.querySelector("input[name=" + filter_value + "]");
            if (input.value == "1") {
                input.value = "";
                btn.classList.remove("btn-primary");
                btn.classList.add("btn-default");
            } else {
                input.value = "1";
                btn.classList.remove("btn-default");
                btn.classList.add("btn-primary");
            }
        }
    }
}



// import 'object-fit-images/dist/ofi.js';
// import 'slick-carousel/slick/slick.min.js';
// import 'fancybox/dist/js/jquery.fancybox';

//   heroSlider();
//   clickOnWhoTab();
//   postsSlider();
//   reviewsSlider();
//   scrolling();
//   menuButtonClick();
//   headerMenuClick();


//   function headerMenuClick() {
//     $('.header__menu li').on('click', function () {
//       if ($(document).width() < 1200 && $(this).children().length > 1) {
//         $(this).toggleClass('active');
//         $(this).children('.sub-menu').slideToggle();
//       }
//     });
//   }



//   function menuButtonClick() {
//     $('.menu-btn').on('click', (function () {
//       openMenu(this);
//     }));
//   }


//   function openMenu(el) {
//     $(el).toggleClass('active');
//     $('.header__menu-outer').toggleClass('active');
//     $('body').toggleClass('menu-opened');
//   }


//   function scrolling() {
//     $(window).scroll(function () {
//       const header = $('.header'),
//         topPosition = $(window).scrollTop();

//       if (topPosition >= 1) {
//         header.addClass('fixed');
//       } else {
//         header.removeClass('fixed');
//       }
//     });
//   }



//   function clickOnWhoTab() {
//     $('.hero__who a').on('click', function (event) {
//       contentTabs(this, event);
//     });
//   }

//   function contentTabs(element, event) {
//     event.preventDefault();
//     if ($('.type-outer').length > 1 && !$(element).hasClass('active')) {
//       $('.type-outer').hide();
//       $('.type-outer[data-content="' + $(element).data('content') + '"]').show();
//     }

//     // show video in hero if choosed "participant"
//     const heroVideo = $('.hero__video');
//     if ($(element).data('content') == 'participant' && $(heroVideo).is(':hidden')) {
//       $(heroVideo).slideDown();
//     } else {
//       $(heroVideo).slideUp();
//     }
//   }

//   function heroSlider() {
//     if (!$('.hero__slider').length || $(document).width() < 1050) return false;
//     const heroSliderElement = $('.hero__slider');

//     $(heroSliderElement).on('init reInit afterChange', function (event, slick, currentSlide) {
//       //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
//       const counter = $('#hero__slider-counter');
//       const i = (currentSlide ? currentSlide : 0) + 1;
//       $(counter).html('<span class="current">' + i + '</span>' + ' / ' + '<span class="total">' + slick.slideCount + '</span>');
//     });

//     ($(heroSliderElement) as any).slick({
//       fade: true,
//       cssEase: 'linear',
//       dots: true,
//       appendDots: $('.hero__slider-navigation'),
//       autoplay: true,
//       autoplaySpeed: 4000,
//     });
//   }

//   function postsSlider() {
//     if (!$('.posts__slider').length) return false;
//     const postsSliderElement = $('.posts__slider');


//     $(postsSliderElement).each(function () {

//       $(this).on('init reInit afterChange', function (event, slick, currentSlide) {
//         //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
//         const counter = $(this).next().find('.posts__slider-counter');
//         const i = (currentSlide ? currentSlide : 0) + 1;
//         $(counter).html('<span class="current">' + i + '</span>' + ' / ' + '<span class="total">' + slick.slideCount + '</span>');
//       });

//       ($(this) as any).slick({
//         dots: true,
//         appendDots: $(this).next(),
//         slidesToShow: 3,
//         infinite: false,
//         responsive: [
//           {
//             breakpoint: 1200,
//             settings: {
//               slidesToShow: 2
//             },
//           },
//           {
//             breakpoint: 767,
//             settings: {
//               slidesToShow: 1,
//               arrows: false
//             }
//           }
//         ]
//       });


//       // calculate dots width
//       const sliderDotsList = $(this).next().find('.slick-dots');
//       const sliderDots = $(sliderDotsList).find('li');

//       if (sliderDots.length <= 3) {
//         $(sliderDotsList).hide();
//       } else {
//         $(sliderDots).find('button').css('width', sliderDotsList.width() / sliderDots.length);
//       }

//     });

//   }

//   function reviewsSlider() {
//     if (!$('.reviews__slider').length) return false;
//     const reviewsSliderElement = $('.reviews__slider');

//     ($(reviewsSliderElement) as any).slick({
//       dots: true,
//       slidesToShow: 2,
//       infinite: false,
//       responsive: [
//         {
//           breakpoint: 767,
//           settings: {
//             arrows: false
//           }
//         },
//         {
//           breakpoint: 576,
//           settings: {
//             slidesToShow: 1
//           }
//         }
//       ]
//     });

//   }

// if (document.querySelector('#login-buttons')) {
//   fetch('.', {
//     headers: {
//       'Accept': 'application/json',
//       'X-Requested-With': 'XMLHttpRequest',
//     },
//   }).then(function (resp) {
//     return resp.json();
//   }).then(function (data) {
//     console.log(data);
//     if (data.is_logged_in) {

//       document.getElementById("login-buttons").remove();
//       const el = <HTMLElement>document.querySelector(".profile-menu");
//       el.style.display = 'block';
//       el.querySelector("img").src = data.profile_picture;
//       el.querySelector("img").alt = data.profile_name;
//       el.querySelector("#fr_p_name").innerHTML = data.profile_name;
//       const fr_p_menu = el.querySelector("#fr_p_menu");
//       for (const x of data.menu) {
//         const li = document.createElement('li');
//         const a = document.createElement('a');
//         li.appendChild(a);
//         fr_p_menu.appendChild(li);
//         a.setAttribute('href', x.url);
//         a.innerHTML = x.name;
//       }
//     }
//   })
// }
