import pluralize = require('pluralize');

/**
 * Special star rating class which turns the input control to the star rating component
 */
export default class StarRating {
    inputElement: HTMLInputElement = null;
    starRatingContainer: Element = null;
    ratingLabel: HTMLElement = null;

    constructor(element: HTMLInputElement) {
        this.inputElement = element;
        this.init();
    }

    init() {
        this.buildStarsContainer();
        this.showRatingValue(this.value);
    }

    buildStarsContainer() {
        // we gona build a stars container right away after the input. input itself is hidden
        this.starRatingContainer = document.createElement('div');
        this.starRatingContainer.classList.add('stars-container');
        this.inputElement.after(this.starRatingContainer);

        // build the star icons
        for (let i = 1; i <= this.maxStars; i++) {
            const starIcon = document.createElement('span');
            starIcon.classList.add('star-icon');
            starIcon.setAttribute('data-star', i + '');
            if (this.isEditable) {
                this.addStartIconEventHandlers(starIcon);
            }
            this.starRatingContainer.appendChild(starIcon);
        }

        if (this.isRatingLabelVisible) {
            this.ratingLabel = document.createElement('span');
            this.ratingLabel.classList.add('rating-label');
            this.starRatingContainer.appendChild(this.ratingLabel);
        }

        this.starRatingContainer.addEventListener('mouseleave', () => {
            this.showRatingValue(this.value);
        });
    }

    get maxStars() {
        return 5;
    }

    get isEditable() {
        return this.inputElement.classList.contains('editable');
    }

    get isRatingLabelVisible() {
        return this.inputElement.classList.contains('show-label');
    }

    addStartIconEventHandlers(starIcon: HTMLElement) {
        starIcon.addEventListener('mousemove', () => {
            const starValue = parseInt(starIcon.getAttribute('data-star'));
            this.showRatingValue(starValue);
        });

        starIcon.addEventListener('click', () => {
            const starValue = parseInt(starIcon.getAttribute('data-star'));
            this.value = starValue;
            this.showRatingValue(this.value);
        });
    }

    showRatingValue(rating: number) {
        const starIcons = this.starRatingContainer.querySelectorAll('.star-icon');
        starIcons.forEach((starIcon: HTMLElement) => {
            const starValue = parseInt(starIcon.getAttribute('data-star'));
            starIcon.classList.toggle('selected', starValue <= rating);
            starIcon.classList.toggle('half-selected', starValue - 1 < rating && starValue > rating);
        })

        this.showLabel(rating);
    }

    showLabel(value: number) {
        if (!this.ratingLabel) {
            return;
        }
        const textValue = pluralize('star', value, true);
        this.ratingLabel.innerText = textValue;
    }

    get value() {
        return parseFloat(this.inputElement.value);
    }

    set value(value: number) {
        this.inputElement.value = value + '';
        this.inputElement.dispatchEvent(new Event('change'));
    }


}
