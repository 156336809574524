export default class NavPillsResponsive {
    navPillsRootElem: HTMLElement = null;
    options: Array<any> = [];
    visibleItemsCount = 0;
    navItemsList: NodeListOf<Element> = null;

    constructor(navPillsRootElem: HTMLElement) {
        this.navPillsRootElem = navPillsRootElem;
        this.init();
    }

    init() {
        this.setupEventHandlers()
    }


    setupEventHandlers() {
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('load', this.handleWindowResize);
    }
    handleWindowResize = () => {
        let full_width = this.navPillsRootElem.offsetWidth;
        const navLinks = <NodeListOf<HTMLLIElement>>this.navPillsRootElem.querySelectorAll('.nav-link');

        if (full_width && this.options.length == 0) {
            // @ts-ignore
            for (let navLink of navLinks) {
                this.options.push(navLink.parentElement.offsetWidth);
            }
        }

        let elem_width = this.options[0] + this.options[this.options.length-1];

        let active_index = 0;
        for (let i = 0; i < navLinks.length - 1; i++) {
            if (navLinks[i].classList.contains("active")) {
                active_index = i;
                break;
            }
        }

        if (active_index > 0) {
            elem_width = elem_width + this.options[active_index];
        }

        let show_more = false;
        for (let i = 1; i < navLinks.length - 1; i++) {

            if (i != active_index)
                elem_width = elem_width + this.options[i];

            if (elem_width > full_width) {
                if (i == navLinks.length - 2 && (show_more == false || i == active_index)) {
                    if (elem_width - this.options[this.options.length-1] < full_width) {
                        navLinks[navLinks.length - 1].classList.add("d-none");
                    } else {
                        if (i != active_index) {
                            show_more = true;
                            navLinks[i].classList.add("d-none");
                        } else
                            navLinks[i].classList.remove("d-none");
                    }
                } else {
                    if (i != active_index) {
                        show_more = true;
                        navLinks[i].classList.add("d-none");
                    } else {
                        navLinks[i].classList.remove("d-none");
                    }
                }

            } else {
                navLinks[i].classList.remove("d-none");
            }
        }
        let dropdown_menu = this.navPillsRootElem.querySelector('.dropdown-menu');
        console.log(dropdown_menu);
        dropdown_menu.innerHTML = '';
        for (let i = 0; i < navLinks.length - 1; i++) {
            if (navLinks[i].classList.contains("d-none")) {
                const clone = <HTMLElement>navLinks[i].cloneNode(true);
                clone.classList.remove("d-none", "nav-link");
                clone.classList.add("dropdown-item");
                console.log(clone);
                dropdown_menu.appendChild(clone);
            }

        }

        let dropdown_button = <HTMLLIElement>this.navPillsRootElem.querySelector('.nav-link.dropdown-toggle')
        if (show_more) {
            dropdown_button.classList.remove("d-none");
        } else {
            dropdown_button.classList.add("d-none");
        }


        this.navPillsRootElem.style.opacity = "1";
    }
}
